/*--
/*  2.2 - Blockquote CSS
/*----------------------------------------*/

.blockquote{
    display: flex;
    align-items: center;
    margin: 0;

    @media #{$small-mobile}{
        display: block;
    }

    & .quote{
        padding: 10px 0;
    }

    & p {
        flex: 1;
        font-size: 24px;
        font-weight: 700 !important;
        font-family: $headings-font-family;
        padding-left: 60px;
        line-height: 1.58;
        color: $secondary;
        margin-bottom: 0;
        margin-top: 0 !important;

        @media #{$large-mobile}{
            font-size: 18px;
            padding-left: 30px;
        }
        @media #{$small-mobile}{
            padding-left: 0;
            padding-top: 15px;
        }
    }    
}