/*--
/*  4.15 - Cases Details CSS
/*----------------------------------------*/



// Cases Details Title Share 
.cases-details-title-share{
    display: flex;
    flex-wrap: wrap;

    & .cases-details-title{
        width: 80%;
        padding-top: 25px;

        @media #{$tablet-device, $large-mobile}{
            width: 100%;
        }

        & .cases-meta{
            display: flex;
            flex-wrap: wrap;

            & li{
                &::after{
                    content: '/';
                    color: #91979d;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0 10px;
                }

                &:last-child{
                    &::after{
                        display: none;
                    }
                }

                & a{
                    letter-spacing: 2px;
                    font-size: 15px;
                    font-weight: 600;
                    color: #91979d;
                    text-transform: uppercase;
                    display: inline-block;

                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
        & .title{
            font-size: 60px;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 0;

            @media #{$desktop-device, $tablet-device}{
                font-size: 50px;
            }
            @media #{$large-mobile}{
                font-size: 40px;
            }
            @media #{$small-mobile}{
                font-size: 28px;
            }
        }
    }
    & .cases-details-share{
        width: 20%;
        padding-top: 25px;

        @media #{$tablet-device, $large-mobile}{
            width: 100%;
        }

        & .lable{
            letter-spacing: 2px;
            font-size: 15px;
            font-weight: 600;
            color: #91979d;
            text-transform: uppercase;
            display: block;
    
            &::before{
                content: '/';
                color: #91979d;
                font-size: 14px;
                margin-right: 5px;
            }
        }
        & .social{
            display: flex;
            margin-top: 30px;
    
            & li{
                margin-right: 20px;
    
                &:last-child{
                    margin-right: 0;
                }

                & a{
                    width: 50px;
                    height: 50px;
                    line-height: 48px;
                    border: 1px solid #dde1e5;
                    color: $dark;
                    display: block;
                    font-size: 16px;
                    text-align: center;
                    border-radius: 50%;
    
                    @media #{$tablet-device}{
                        width: 50px;
                        height: 50px;
                        line-height: 48px;
                    }
    
                    &:hover{
                        color: $white;
                        background-color: $primary;
                        border-color: $primary;
                    }
                }
            }
        }
    }
}

// Cases Details Wrapper 
.cases-details-wrapper{
    & .cases-details-images{
        padding-top: 100px;

        @media #{$tablet-device, $large-mobile}{
            padding-top: 60px;
        }
         
        & img{
            width: 100%;
        }
    }
    & .cases-details-project-info{
        background-color: #f5f9fc;
        max-width: 500px;
        padding: 20px 50px 35px;
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-top: -104px;
        margin-bottom: 50px;
        position: relative;

        @media #{$large-mobile}{
            padding: 0px 30px 25px;
            max-width: 450px;
        }
        @media #{$small-mobile}{
            margin-top: 30px;
            display: block;
        }

        & .project-info-title{
            width: 50%;
            padding-top: 25px;

            @media #{$small-mobile}{
                width: 100%;
            }

            & .title{
                font-size: 25px;
                font-weight: 700;
                color: $secondary;
                margin-bottom: 0;
            }
        }
        & .project-info-list{
            width: 50%;
            padding-top: 25px;

            @media #{$small-mobile}{
                width: 100%;
            }

            & .info-list{
                & li{
                    margin-top: 5px;

                    & .title{
                        display: inline-block;
                        width: 80px;
                    }
                    & span{
                        font-family: $headings-font-family;
                        font-weight: 700;
                        font-size: 18px;
                        color: $dark;
                    }
                }
            }
        }
    }
    & .cases-details-blockquote{
        display: flex;
        margin: 0;
        padding-right: 40px;
        margin-top: 40px;

        @media #{$small-mobile}{
            display: block;
            padding-right: 0;
        }

        & .quote{
            padding: 10px 0;
        }
        
        & .quote-content{
            flex: 1;
            padding-left: 60px;

            @media #{$desktop-device, $large-mobile}{
                padding-left: 30px;
            }
            @media #{$small-mobile}{
                padding-left: 0;
                padding-top: 15px;
            }

            & .text {
                font-size: 24px;
                font-weight: 700 !important;
                font-family: $headings-font-family;
                line-height: 1.58;
                color: $secondary;
                margin-top: 0 !important;

                @media #{$desktop-device}{
                    font-size: 20px;
                }
        
                @media #{$large-mobile}{
                    font-size: 18px;
                }
                @media #{$small-mobile}{
                    padding-left: 0;
                }
            }
            & p{}
        }        
    }
    & .cases-details-download{
        padding: 70px;
        background-color: #f5f9fc;
        position: relative;
        overflow: hidden;
        z-index: 1;
        margin-top: 40px;

        @media #{$desktop-device}{
            padding: 40px;
        }
        @media #{$small-mobile}{
            padding: 30px;
        }

        &::before,
        &::after{
            position: absolute;
            content: '';
            width: 164px;
            height: 164px;
            border-radius: 50%;
            background-color: $secondary;
            top: -74px;
            right: -44px;
            z-index: -1;
        }
        &::after{
            background-color: $primary;
            top: -54px;
            right: -54px;
            z-index: -2;
        }

        & .title{
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: -10px;

            @media #{$small-mobile}{
                font-size: 24px;
            }
        }
        & .download-link{
            padding-top: 12px;

            & li{
                margin-top: 20px;

                & a{
                    display: block;
                    height: 75px;
                    line-height: 73px;
                    padding: 0 40px;
                    font-size: 15px;
                    color: #6c7178;
                    background-color: $white;
                    border: 1px solid #edeef0;
                    transition: $transition-base;
                    white-space: nowrap;

                    @media #{$small-mobile}{
                        height: 60px;
                        line-height: 58px;
                        padding: 0 20px;
                    }

                    & i{
                        display: block;
                        float: right;
                        line-height: 73px;
                        color: $primary;
                        transition: $transition-base;

                        @media #{$small-mobile}{
                            line-height: 58px;
                        }
                    }

                    &:hover{
                        background-color: $secondary;
                        border-color: $secondary;
                        color: $white;

                        & i{
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    & .cases-details-description{
        padding-top: 50px;

        & .title{
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 30px;

            @media #{$small-mobile}{
                font-size: 24px;
            }
        }
    }
    & .cases-details-next-preview{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 30px 30px;
        background-color: #f5f9fc;
        margin-top: 75px;

        @media #{$large-mobile}{
            padding: 0 10px 10px;
            margin-top: 55px;
        }

        & .cases-details-next,
        & .cases-details-preview{
            margin-top: 10px;

            @media #{$small-mobile}{
                width: 100%;
                text-align: center;
            }

            & .next,
            & .preview{
                
                & a{
                    font-size: 28px;
                    font-weight: 700;
                    font-family: $headings-font-family;
                    color: $dark;
                    transition: $transition-base;

                    @media #{$tablet-device}{
                        font-size: 20px;
                    }
                    @media #{$large-mobile}{
                        font-size: 18px;
                    }

                    & i{
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                        text-align: center;
                        font-size: 16px;
                        background-color: $dark;
                        color: $white;
                        display: inline-block;
                        border-radius: 50%;
                        margin-right: 20px;
                        transition: $transition-base;

                        @media #{$tablet-device, $large-mobile}{
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            margin-right: 5px;
                        }
                        @media #{$small-mobile}{
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }               

                &:hover{
                    & a{
                        color: $secondary;

                        & i{
                            background-color: $secondary;
                            color: $white;
                        }
                    }
                }
            }
        }

        & .cases-details-preview{
            & .preview{
                & a{
                    & i{
                        margin-right: 0;
                        margin-left: 20px;

                        @media #{$large-mobile}{
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}










