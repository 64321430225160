/*--
/*  4.9 - Page Banner CSS
/*----------------------------------------*/

// Page Banner Section 
.page-banner-section{
    position: relative;
    z-index: 1;
    height: 660px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media #{$desktop-device}{
        height: 550px;
    }
    @media #{$tablet-device, $large-mobile}{
        height: 460px;
    }
    @media #{$small-mobile}{
        height: 360px;
    }

    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.6);
        top: 0;
        left: 0;
        z-index: -1;
    }
    &::after{
        position: absolute;
        content: '';
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 728px;
        height: 728px;
        background-color: $primary;
        opacity: 0.15;
        z-index: -1;
        border-radius: 50%;

        @media #{$tablet-device, $large-mobile}{
            width: 528px;
            height: 528px;
        }
        @media #{$small-mobile}{
            width: 328px;
            height: 328px;
        }
    }
}

// Page Banner Content
.page-banner-content{
    text-align: center;
    padding-top: 94px;

    @media #{$desktop-device}{
        padding-top: 90px;
    }
    @media #{$small-mobile}{
        padding-top: 75px;
    }

    & .title{
        font-weight: 700;
        font-size: 70px;
        color: $white;

        @media #{$tablet-device, $large-mobile}{
            font-size: 50px;
        }
        @media #{$small-mobile}{
            font-size: 30px;
        }
    }
    & .breadcrumb{
        justify-content: center;
        margin-bottom: 0;

        & li{
            font-size: 16px;
            font-weight: 500;
            color: $white;  
            position: relative;

            @media #{$small-mobile}{
                font-size: 14px;
            }

            & + li{
                &::before{
                    content: '//';
                    color: $white;
                    margin: 0 10px;
                }
            }

            & a{}

            &.active{
                color: $primary;
            }
        }
    }
}














