/*--
/*  4.10 - About CSS
/*----------------------------------------*/

// About Images 
.about-images{
    position: relative;
    margin-top: 50px;

    & .image{
        & img{
            width: 100%;
        }
    }
    & .about-icon{
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba($primary, 0.85);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

// About Content 
.about-content{
    padding-left: 70px;
    margin-top: 50px;

    @media #{$desktop-device, $tablet-device, $large-mobile}{
        padding-left: 0;
    }

    & .about-title{
        & .sub-title{
            font-size: 15px;
            font-weight: 600;
            font-family: $font-family-base;
            color: $primary;
            margin-bottom: 0;
            text-transform: uppercase;
    
            & span{
                color: $secondary;
            }
        }
        & .main-title{
            font-size: 58px;
            font-weight: 400;
            margin-bottom: 0;
            margin-top: 20px;
    
            @media #{$desktop-device}{
                font-size: 46px;
            }
            @media #{$large-mobile}{
                font-size: 40px;
            }
            @media #{$small-mobile}{
                font-size: 30px;
            }
    
            & span{
                font-weight: 700;
            }
        }
    }

    & .single-item{
        display: flex;
        margin-top: 50px;

        & .item-icon{
            & img{}
        }
        & .item-content{
            flex: 1;
            padding-left: 60px;
            margin-top: -4px;

            @media #{$desktop-device, $small-mobile}{
                padding-left: 30px;
            }

            & .title{
                font-size: 25px;
                font-weight: 700;
                margin-bottom: 0;

                @media #{$small-mobile}{
                    font-size: 22px;
                }

                & span{
                    color: $primary;
                }
            }
            & p{
                margin-top: 20px;
                margin-bottom: 0;
            }
        }
    }

    & > p{
        margin-bottom: 0;
        margin-top: 25px;
    }
}















