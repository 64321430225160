/*--
/*  3.2 - Mobile Menu CSS
/*----------------------------------------*/

// Mobile Menu 
.mobile-menu{
    position: fixed;
    top: 0;
    width: 300px;
    height: 100%;
    left: -305px;
    background-color: $white;
    z-index: 999;
    padding-top: 70px;
    transition: $transition-base;

    &.open{
        left: 0;
    }

    & .menu-close{
        position: absolute;
        top: 20px;
        right: 25px;
        font-size: 30px;
        color: $secondary;

    }
}


// Mobile Button
.mobile-btn{
    padding: 30px;

    & .btn{
        width: 100%;
        display: block;

        &::before{
            width: 300px;
            height: 300px;
        }
    }
}


// Mobile Menu
.mobile-menu-items{
    & ul{
        border-top: 1px solid #eeecec;

        & li{
            position: relative;

            & a{
                display: block;
                width: 100%;
                background: none;
                text-align: left;
                padding: 8px 20px;
                border: 0;
                border-bottom: 1px solid #eeecec;
                font-size: 15px;
                color: $secondary;
            }

            & .mobile-menu-expand{
                position: absolute;
                right: 20px;
                top: 12px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                
                &::before{
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: $secondary;
                    top: 50%;
                    transform: translateY(-50%);
                }
                
                &::after {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    background-color: $secondary;
                    left: 50%;
                    bottom: 0;
                    @include transition(0.3s);
                    transform: translateX(-50%);
                }
            }

            &.active-expand{
                & > .mobile-menu-expand{
                    &::after {
                        height: 0;
                    }
                }
            }

            ul{
                border-top: 0;

                & li{
                    & a{
                        padding-left: 12%;
                    }

                    ul{
                        & li{
                            & a{
                                padding-left: 24%;
                            }
                        }
                    }
                }
            }
        }        
    }
}





