/*--
/*  4.7 - Customer CSS
/*----------------------------------------*/


// Customer  Wrapper
.customer-wrapper{
    position: relative;
    padding-top: 80px;

    @media #{$tablet-device, $large-mobile}{
        padding-top: 60px;
    }
}

// Single Customer 
.single-customer{
    background-color: #f5f9fc;
    position: relative;
    padding: 60px;
    transition: $transition-base;
    overflow: hidden;

    @media #{$desktop-device}{
        padding: 40px;
    }
    @media #{$small-mobile}{
        padding: 20px;
    }

    &::before{
        position: absolute;
        content: '';
        bottom: 30px;
        right: 30px;
        width: 0;
        height: 0;
        border-bottom: 65px solid $secondary;
        border-left: 65px solid transparent;
        transition: $transition-base;
    }
    &::after{
        position: absolute;
        content: '';
        top: -40px;
        right: -35px;
        width: 198px;
        height: 198px;
        background-image: url(../images/quote-1.png);
        background-position: bottom left;
        background-repeat: no-repeat;
        transition: $transition-base;
        background-size: cover;

        @media #{$small-mobile}{
            width: 128px;
            height: 128px;
        }
    }

    & .customer-author{
        position: relative;
        z-index: 1;

        &::before{
            position: absolute;
            content: '';
            width: 140px;
            height: 140px;
            top: -20px;
            left: -20px;
            border: 3px dashed $secondary;
            border-radius: 50%;
            transition: $transition-base;
            z-index: -2;
        }

        &::after{
            position: absolute;
            content: '';
            top: -85px;
            left: -50px;
            background-color: #f5f9fc;
            width: 140px;
            height: 180px;
            transform: rotate(55deg);
            z-index: -1;
            transition: $transition-base;
        }

        & img{
            border-radius: 50%;
            border: 4px solid $white;
            width: 100px;
        }
    }
    & .customer-content{
        padding-top: 50px;

        & p{
            font-size: 21px;
            font-weight: 600;
            font-family: $headings-font-family;
            margin-bottom: 0;
            transition: $transition-base;

            @media #{$small-mobile}{
                font-size: 16px;
            }
        }
        & .name{
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 0;
            margin-top: 22px;
            transition: $transition-base;

            @media #{$small-mobile}{
                font-size: 20px;
            }
        }
        & .designation{
            color: $secondary;
            font-size: 14px;
            display: block;   
            margin-top: 12px;       
            transition: $transition-base;  
        }
    }

    &:hover{
        background-color: $secondary;

        &::before{
            border-bottom-color: $primary;
        }
        &::after{
            background-image: url(../images/quote-2.png);
        }

        & .customer-author{    
            &::before{
                border-color: $white;
            }
            &::after{
                background-color: $secondary;
            }
        }
        & .customer-content{    
            & p{
                color: #d9e2ea;
            }
            & .name{
                color: $white;
            }
            & .designation{
                color: #eeeeee;
            }
        }
    }
}

// Single Customer 

.customer-pagination-arrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 80px;

    @media #{$tablet-device, $large-mobile}{
        padding-top: 40px;
    }

    & .swiper-pagination{
        position: relative;
        bottom: 0;
        font-size: 30px;
        font-weight: 300;
        color: #c9c9c9;
        text-align: left;

        & .swiper-pagination-current{
            color: $primary;
        }
        & .swiper-pagination-total{}
    }

    & .swiper-arrows{
        display: flex;
        align-items: center;

        & .swiper-button-next,
        & .swiper-button-prev{
            position: relative;
            top: 0;
            margin-top: 0;
            width: 55px;
            height: 55px;
            line-height: 51px;
            text-align: center;
            border: 2px solid #c9c9c9;
            display: block;
            left: 0;
            right: 0;
            margin: 0 10px;
            color: #323232;
            border-radius: 50%;
            font-size: 15px;
            transition: $transition-base;
            outline: none;
            opacity: 1;

            @media #{$small-mobile}{
                width: 45px;
                height: 45px;
                line-height: 41px;
            }

            &:hover{
                color: $secondary;
                border-color: $secondary;
            }

            &::after{
                display: none;
            }
        }

        & .swiper-button-prev{}
    }
}





