/*--
/*  4.4 - Why Choose Us CSS
/*----------------------------------------*/

// Why Choose Section
.why-choose-section{
    overflow: hidden;
}



// Why Choose Content
.why-choose-content{
    max-width: 500px;
    margin-top: 45px;
}


// Why Choose Title
.why-choose-title{
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 37px;

    & .sub-title{
        font-size: 15px;
        font-weight: 600;
        font-family: $font-family-base;
        color: $primary;
        margin-bottom: 0;
        text-transform: uppercase;

        & span{
            color: $secondary;
        }
    }
    & .main-title{
        font-size: 58px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 20px;

        @media #{$desktop-device}{
            font-size: 46px;
        }
        @media #{$large-mobile}{
            font-size: 40px;
        }
        @media #{$small-mobile}{
            font-size: 30px;
        }

        & span{
            font-weight: 700;
        }
    }
}


// Why Choose Items
.why-choose-items{
    & .single-item{
        display: flex;
        margin-top: 50px;

        & .item-icon{
            & img{}
        }
        & .item-content{
            flex: 1;
            padding-left: 60px;
            margin-top: -4px;

            @media #{$desktop-device, $small-mobile}{
                padding-left: 30px;
            }

            & .title{
                font-size: 25px;
                font-weight: 700;
                margin-bottom: 0;

                @media #{$small-mobile}{
                    font-size: 22px;
                }

                & span{
                    color: $primary;
                }
            }
            & p{
                margin-top: 20px;
                margin-bottom: 0;
            }
        }
    }
}


// Why Choose Images
.why-choose-images{
    position: relative;
    z-index: 1;
    margin-top: 50px;

    @media #{$extraBig-device}{
        right: -30px;
    }
    @media #{$tablet-device}{
        width: 575px;
        margin-left: auto;
        margin-right: auto;
    }
    @media #{$large-mobile}{
        width: 470px;
        margin-left: auto;
        margin-right: auto;
    }
    @media #{$small-mobile}{
        width: 280px;
    }
    
    & .choose-images{
        width: 525px;
        text-align: center;
        margin-left: auto;
        padding-right: 18px;

        @media #{$desktop-device, $large-mobile}{
            width: 450px;
        }
        @media #{$tablet-device,  $large-mobile}{
            margin: 0 auto;
        }
        @media #{$small-mobile}{
            width: 280px;
        }

        & img{
            position: relative;

            @media #{$large-mobile}{
                width: 325px;
            }
            @media #{$small-mobile}{
                width: 215px;
            }
        }
    }
    & .choose-shape{
        width: 525px;
        height: 525px;
        border-radius: 50%;
        background-color: $secondary;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        overflow: hidden;

        @media #{$desktop-device}{
            width: 450px;
            height: 450px;
        }
        @media #{$tablet-device}{
            left: 0;
            margin: 0 auto;
        }
        @media #{$large-mobile}{
            width: 450px;
            height: 450px;
            margin: 0 auto;
            left: 0;
        }
        @media #{$small-mobile}{
            width: 280px;
            height: 280px;
        }

        &::after{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $primary;
            top: -200.5px;
            left: 0;
        }
    }
    & .choose-arrow{
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        text-align: center;
        width: 100%;
    }
}

