/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');


/*-- Common Style --*/
*,
*::after,
*::before {
    box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    position: relative;
    outline: none;
    visibility: visible;
    overflow-X: hidden;

    color: "";
    &[dir="rtl"] {
        text-align: right;
    }
}



a,
button {
    transition: $transition-base;
    outline: 0;

    &:focus{
        outline: 0;
    }
}

a{
    text-decoration: none;
    color: inherit;
}

p{
    font-size: 15px;
    line-height: 1.8;
}

p:last-child{
    margin-bottom: 0;
}

ul,ol{
    padding: 0;
    list-style: none;
    margin: 0;
}

img{
    max-width: 100%;
}

/* Section Style */
.section,
.main-wrapper {
    float: left;
    width: 100%;
}

/* Custom Container */
.custom-container{
    max-width: 1750px;
}

/* Section Padding */
.section-padding{
    padding-top: 160px;
    padding-bottom: 160px;

    @media #{$desktop-device}{
        padding-top: 110px;
        padding-bottom: 110px;
    }
   
    @media #{$tablet-device}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-padding-02{
    padding-top: 160px;
    
    @media #{$desktop-device}{
        padding-top: 110px;
    }
    @media #{$tablet-device}{
        padding-top: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 60px;
    }
}

/* Section Padding */
.section-title{
    & .sub-title{
        font-size: 15px;
        font-weight: 600;
        font-family: $font-family-base;
        color: $primary;
        margin-bottom: 0;
        text-transform: uppercase;

        & span{
            color: $secondary;
        }
        
        @media #{$small-mobile}{
            font-size: 13px;
        }
    }
    & .main-title{
        font-size: 65px;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 20px;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            font-size: 40px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
        }
    }

    &.shape{
        position: relative;
        padding-top: 75px;
        padding-bottom: 70px;
        z-index: 1;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            padding-top: 50px;
            padding-bottom: 45px;
        }
        @media #{$small-mobile}{
            padding-top: 35px;
            padding-bottom: 30px;
        }

        &::before,
        &::after{
            position: absolute;
            content: '';
            width: 260px;
            height: 260px;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.04;
            z-index: -1;

            @media #{$desktop-device, $tablet-device, $large-mobile}{
                width: 180px;
                height: 180px;
            }
            @media #{$small-mobile}{
                width: 130px;
                height: 130px;
            }
        }
        &::before{
            background-color: $secondary;
            right: 45%;
        }
        &::after{
            background-color: $primary;
            left: 45%;
        }
    }
}

// BG Color 
.bg-color-01{
    background-color: #f5f9fc;
}

/* Overlay */
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    @include transition(all 0.3s linear);

    &.open{
        opacity: 0.7;
        visibility: visible;
    }
}

// Page Pagination
.page-pagination{
    padding-top: 80px;
    
    @media #{$large-mobile}{
        padding-top: 60px;
    }

    & .pagination{
        & li{
            margin-right: 12px;

            &:last-child{
                margin-right: 0;
            }

            & a{
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color: $dark;
                background-color: #f5f9fc;
                display: block;
                transform: $transition-base;

                &.active,
                &:hover{
                    color: $white;
                    background-color: $primary;
                }
            }
        }
    }
}

