/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/

// Footer Section 
.footer-section{
    background-color: $dark;
}

// Footer Top
.footer-top{
    padding-top: 20px;
    padding-bottom: 50px;
    border-bottom: 1px solid #293441;
}

// Footer Logo
.footer-logo{
    margin-top: 30px;

    & a{
        & img{}
    }
}

// Footer Call
.footer-call{
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: center;

    & .call-icon{
        width: 60px;
        height: 60px;
        border: 1px solid #293441;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media #{$tablet-device}{
            width: 50px;
            height: 50px;
        }
    }

    & .call-content{
        flex: 1;
        padding-left: 30px;

        @media #{$tablet-device}{
            padding-left: 10px;
        }

        & .title{
            font-size: 22px;
            color: $primary;
            font-weight: 600;
            margin-bottom: 0;

            @media #{$tablet-device}{
                font-size: 18px;
            }
        }

        & p{
            font-size: 25px;
            color: $white;
            font-weight: 400;
            margin-bottom: 0;
            white-space: nowrap;

            @media #{$tablet-device}{
                font-size: 18px;
            }
        }
    }
}

// Footer Social 
.footer-social{
    margin-top: 30px;

    & .social{
        display: flex;
        justify-content: flex-end;

        @media #{$large-mobile}{
            justify-content: flex-start;
        }

        & li{
            margin-right: 20px;

            &:last-child{
                margin-right: 0;
            }

            & a{
                width: 60px;
                height: 60px;
                line-height: 58px;
                border: 1px solid #293441;
                color: $white;
                display: block;
                font-size: 16px;
                text-align: center;
                border-radius: 50%;

                @media #{$tablet-device}{
                    width: 50px;
                    height: 50px;
                    line-height: 48px;
                }

                &:hover{
                    color: $white;
                    background-color: $primary;
                    border-color: $primary;
                }
            }
        }
    }
}


// Footer Copyright
.footer-copyright{
    background-color: #232e3a;

}

// Copyright Wrapper
.copyright-wrapper{
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    padding-top: 10px;

    @media #{$tablet-device, $large-mobile}{
        display: block;
        text-align: center;
    }
}

// Copyright Text
.copyright-text{
    padding-top: 15px;

    & p{
        color: #8690a1;
        font-size: 16px;
        font-weight: 300;
        
        & span{
            color: $primary;
            text-transform: uppercase;
        }
        
        & i{
            color: #ff0000;
        }
        
        & a{
            color: $primary;
            transition: $transition-base;
            font-weight: 500;
            
            &:hover{
                color: $primary;
            }
        }
    }
}

// Copyright Text
.copyright-link{
    padding-top: 15px;

    & a{
        font-weight: 300;
        font-size: 15px;
        color: #a6afba;
        position: relative;

        & + a{
            &::before{
                content: '|';
                font-size: 15px;
                color: #a6afba;
                margin-left: 15px;
                margin-right: 20px;
            }
        }

        &:hover{
            color: $primary;
        }
    }
}

// Back to Top
.back-to-top{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    z-index: 99;
    text-align: center;
    display: none;
    box-shadow: 0px 0px 30px 0px rgba(156,156,156,0.25);    
    transition: all 0.3s linear;
    color: $white;
    background-color: $primary;

    &:hover{
        color: $white;
        background-color: $dark;    
    }
}

