/*--
/*  4.16 - FAQ CSS
/*----------------------------------------*/

// FAQ Section 
.faq-section{
    display: flex;
    flex-wrap: wrap;
}


// FAQ Question 
.faq-question{
    width: 60%;
    padding-left: 100px;
    padding-right: 100px;

    @media #{$laptop-device, $desktop-device}{
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$tablet-device, $large-mobile}{
        width: 100%;
    }
    @media #{$large-mobile}{
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$small-mobile}{
        padding-left: 30px;
        padding-right: 30px;
    }
}

// Single FAQ 
.single-faq{
    display: flex;
    max-width: 785px;
    border-bottom: 1px solid #e8ecef;
    padding-bottom: 15px;
    margin-left: auto;

    @media #{$small-mobile}{
        display: block;
    }

    & + .single-faq{
        margin-top: 50px;
    }

    & .faq-number{
        & .number{
            width: 75px;
            height: 75px;
            line-height: 75px;
            background-color: $secondary;
            color: $white;
            text-align: center;
            font-size: 15px;
            font-weight: 600;
            display: inline-block;
            transition: $transition-base;
            position: relative;
            z-index: 1;            

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background-color: $secondary;
                bottom: 0px;
                right: 0px;
                z-index: -1;
                transition: $transition-base;
            }
            &::after{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                background-color: #e9eef2;
                bottom: -10px;
                right: -10px;
                z-index: -2;
                transition: $transition-base;
            }
        }
    }
    & .faq-content{
        flex: 1;
        padding-left: 60px;

        @media #{$large-mobile}{
            padding-left: 40px;
        }
        @media #{$small-mobile}{
            padding-left: 0;
            padding-top: 35px;
        }

        & .title{
            margin-bottom: 0;
            font-size: 27px;
            font-weight: 700;
            margin-top: -6px;
            transition: $transition-base;

            @media #{$large-mobile}{
                font-size: 24px;
            }
        }
        & p{
            margin-top: 15px;
            margin-bottom: 0;
        }
    }

    &:hover{
        & .faq-number{
            & .number{
                background-color: $primary;
                color: $white;

                &::before{
                    background-color: $primary;
                }
            }
        }
        & .faq-content{    
            & .title{
                color: $primary;
            }
        }
    }
}


// FAQ Form 
.faq-form{
    padding-left: 100px;
    padding-right: 100px;
    width: 40%;

    @media #{$laptop-device, $desktop-device}{
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$tablet-device, $large-mobile}{
        width: 100%;
    }
    @media #{$large-mobile}{
        padding-left: 50px;
        padding-right: 50px;
    }
    @media #{$small-mobile}{
        padding-left: 30px;
        padding-right: 30px;
    }
    
    & .faq-form-title{
        margin-top: -17px;

        & .title{
            font-size: 50px;
            font-weight: 400;
            margin-bottom: 0;
            padding-bottom: 40px;

            @media #{$desktop-device, $large-mobile}{
                font-size: 36px;
            }

            & span{
                font-size: 60px;
                font-weight: 700;

                @media #{$desktop-device, $large-mobile}{
                    font-size: 40px;
                }
            }
        }
        & .line{
            width: 50px;
            height: 4px;
            background-color: $primary;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                width: 5px;
                height: 100%;
                top: 0;
                left: 4px;
                background-color: #f5f9fc;
            }
        }
    }
    & .form-wrapper{
        padding-top: 20px;

        & .single-form{
            & input,
            & textarea{
                background-color: transparent;
            }
        }
        
    }
}













