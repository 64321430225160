/*--
/*  4.11 - Video CSS
/*----------------------------------------*/


// Video Title 
.video-title{
    text-align: center;
    margin-top: -15px;

    & .title{
        font-size: 58px;
        font-weight: 700;

        @media #{$desktop-device, $tablet-device}{
            font-size: 40px;
        }
        @media #{$large-mobile}{
            font-size: 30px;
        }
        @media #{$small-mobile}{
            font-size: 20px;

            & br{
                display: none;
            }
        }

        & span{
            font-style: italic;
            color: $primary;
        }
    }
}


// Video Wrapper 
.video-wrapper{
    position: relative;
    margin-top: 80px;

    @media #{$tablet-device, $large-mobile}{
        margin-top: 60px;
    }

    & img{
        width: 100%;
    }

    & .play{
        width: 90px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        font-size: 16px;
        color: $white;
        background-color: $primary;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        @media #{$large-mobile}{
            width: 50px;
            height: 50px;
            line-height: 50px;
        }

        &:hover{
            background-color: $secondary;
            color: $white;
        }

        &::before{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            border: 1px solid $white;
            top: 0;
            left: 0;
            border-radius: 50%;
            z-index: -1;
            animation: video 2s infinite;
            transition: $transition-base;
        }
    }
}

@-webkit-keyframes video {
   0%{
    transform: scale(1.2);
        opacity: 1;
    }    
    100%{
        transform:  scale(1.5);
        opacity: 0;
    }
}

@keyframes video {
    0%{
        transform: scale(1.2);
        opacity: 0.7;
    }    
    100%{
        transform:  scale(1.5);
        opacity: 0;
    }
}

