/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/

// Slider Section 
.slider-section{
    background-color: $dark;
    position: relative;
    overflow: hidden;
    z-index: 2;

    & .slider-shape-01{
        width: 660px;
        height: 660px;
        position: absolute;
        top: -110px;
        right: 18.5%;
        background-color: $white;
        opacity: 0.02;
        border-radius: 50%;
        z-index: -1;

        @media #{$desktop-device, $tablet-device}{
            width: 520px;
            height: 520px;
        }
        @media #{$small-mobile}{
            width: 300px;
            height: 300px;
        }
    }

    & .slider-shape-02{        
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;

        @media #{$laptop-device}{
            width: 525px;
        }
        @media #{$desktop-device}{
            width: 425px;
        }
        @media #{$tablet-device, $large-mobile}{
            width: 50%;
            margin-left: auto;
        }
    }
}


// Single Slider 
.single-slider{
    padding-top: 144px;
    display: flex;
    flex-wrap: wrap;
}

// Slider Left & Right 
.slider-left,
.slider-right{
    width: 50%;

    @media #{$tablet-device, $large-mobile}{
        width: 100%;
    }
}
.slider-left{
    align-self: center;
    padding-bottom: 43px;

    @media #{$tablet-device, $large-mobile}{
        padding-bottom: 0;
    }
}

// Slider Images 
.slider-images{
    @media #{$tablet-device, $large-mobile}{
        padding-top: 50px;
        max-width: 480px;
        margin: 0 auto;
    }
}


// Slider Content 
.slider-content{
    width: 660px;
    height: 660px;
    background-color: $secondary;
    padding: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: auto;
    position: relative;
    z-index: 2;

    @media #{$desktop-device}{
        width: 520px;
        height: 520px;
    }
    @media #{$tablet-device}{
        width: 520px;
        height: 520px;
        margin-right: 70px;
    }
    @media #{$large-mobile}{
        width: 500px;
        height: 500px;
    }
    @media #{$small-mobile}{
        width: 300px;
        height: 300px;
        margin: 0 auto;
    }

    &::before{
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $secondary;
        z-index: -1;
        border-radius: 50%;
    }
    &::after{
        position: absolute;
        content: '';
        top: 0;
        left: -16%;
        width: 100%;
        height: 100%;
        background-color: $primary;
        z-index: -2;
        border-radius: 50%;

        @media #{$desktop-device, $large-mobile}{
            left: -10%;
        }
        @media #{$small-mobile}{
            left: -5%;
        }
    }

    & .content-wrapper{

    }

    & .sub-title{
        color: $white;
        font-size: 18px;
        font-weight: 600;
        font-family: $font-family-base;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 2px;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            font-size: 16px;
        }

        @media #{$small-mobile}{
            font-size: 14px;
        }
    }
    & .main-title{
        font-size: 60px;
        font-weight: 700;
        color: $white;
        line-height: 1.3;
        margin-bottom: 0;
        margin-top: 26px;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            font-size: 44px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
            margin-top: 10px;
        }

        & strong{
            font-size: 100px;

            @media #{$desktop-device, $tablet-device, $large-mobile}{
                font-size: 80px;
            }
            @media #{$small-mobile}{
                font-size: 40px;
            }
        }
    }
    & .btn{
        margin-top: 60px;
        width: 225px;

        @media #{$small-mobile}{
            height: 45px;
            line-height: 41px;
            padding: 0  25px;
            width: auto;
            margin-top: 30px;
        }
    }
}


// Animation Style 
.animation-style-01{

    & .slider-images,
    & .slider-content,
    & .slider-content-02{
        opacity: 0;
    }

    &.swiper-slide-active{
        & .slider-content{
            opacity: 1;

            & .sub-title{
                animation-name: fadeInUpBig;
                animation-delay: 0.4s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .main-title{
                animation-name: fadeInUpBig;
                animation-delay: 0.8s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .btn{
                animation-name: fadeInUpBig;
                animation-delay: 1.2s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }

        & .slider-images{
            opacity: 1;

            & img{
                animation-name: fadeInUpBig;
                animation-delay: 1.2s;
                animation-duration: 1.3s;
                animation-fill-mode: both;
            }
        }
    }
}


// Slider Active
.slider-active{
    & .swiper-pagination{
        text-align: left;
        bottom: 60px;
        left: 70px;

        @media #{$desktop-device, $tablet-device}{
            bottom: 40px;
            left: 50px;
        }

        @media #{$large-mobile}{
            left: 30px;
            bottom: 20px;
        }

        & .swiper-pagination-bullet{
            outline: none;            
            background-color: $white;
            transition: $transition-base;
            opacity: 1;
            width: 8px;
            height: 8px;
            display: block;
            position: relative;
            margin: 8px 0;

            &.swiper-pagination-bullet-active{
                background-color: $primary;

                &::before{
                    border-color: $primary;
                }
            }
        }
    }
}